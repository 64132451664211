.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.agenda {
  padding: 3rem 0;
  background-image: url("./assets/images/bkg.jpg");
  background-position: center;
}

.agendaBlock {
  border-left: 2px solid #002855;
  padding: 2rem 3rem;
  position: relative;
}

.agendaBlock:after{
  content: "";
  position: absolute;
  top:45%;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  background: #002855;
  left: -0.6rem;
}

.live-page {
  /*height: max(100vh, 56rem);*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*background-image: url("./assets/images/bkg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;*/
  margin: 1.5rem 0;
  padding: 0 0.5rem;

  position: relative;
}

.custom-textarea{
  resize: none;
  width: 100%;
  font-family: inherit;
  font-size: 1rem;
}

.live-logo {
  position: absolute;
  z-index: 99;
  left: 2rem;
  top: 0;
  max-width: 8rem;
  padding: 1.3rem 1rem 1rem;
  background: #002855;
}

.logo-container{
  background: #002855;
}

.title{
  max-width: 25rem;
}

@media screen and (max-width: 1000px){
  .live-logo{
    position: relative;
    left: auto;
    margin: auto;
    background: transparent;
    max-width: 6rem;
    padding: 1rem;
  }

  .live-page{
    margin-top: 1rem;
  }

  .title{
    max-width: 20rem;
  }

  .logo-container {
    text-align: center;
  }
}
